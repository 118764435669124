import React from 'react';
import PropTypes from 'prop-types';
import style from './FromPage.module.scss';

const FromPage = ({ link }) => {
  return (
    <div className={style.wrapper}>
      {link && (
        <iframe
          id="JotFormIFrame-202922344366353"
          title="Partner Onboarding Form"
          role="form"
          allowtransparency="true"
          allowFullScreen="true"
          allow="geolocation; microphone; camera"
          src={link?.text}
          frameBorder="0"
          style="min-width:100%;max-width:100%;height:2450px;border:none;"
          scrolling="no"
        ></iframe>
      )}
    </div>
  );
};

export default FromPage;

FromPage.propTypes = {
  content: PropTypes.array.isRequired,
  link: PropTypes.string,
};
